import React, { useEffect, useState } from "react";
import HOC from "../../Common/HOC";
import a from "../../images/sign.png";
import "./puja.css";
import { notificationHandler } from "../utils/Notification";
import { useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Skeleton } from "@mui/material";
import blogapi, { blog_list_api, get_blogapi } from "../api/blogapi";
import DataNotFound from "../DataNotFound";
import { get_all_puja_api } from "../api/puja";
import Carousel from "react-material-ui-carousel";

const Puja = () => {
  const [isloading, setisloading] = useState(false);
  const [PujaArray, setPujaArray] = useState([]);
  const [promotionalBanner, setpromotionalBanner] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // window.scrollTo({
    //   top: 530,
    //   behavior: "smooth",
    // });
    get_all_blogdata();
  }, []);

  const get_all_blogdata = async (data, index) => {
    setisloading(true);
    try {
      const res = await get_all_puja_api();
      if (res.data.status) {
        setpromotionalBanner(res?.data?.promotionalBanner);
        setPujaArray(res?.data?.data[0]?.result);
        setisloading(false);
      } else {
        setisloading(false);
        notificationHandler({ type: "danger", msg: res?.data?.message });
        console.log("data response error:::", res);
      }
    } catch (error) {
      console.log("data response error:::", error);
      notificationHandler({ type: "danger", msg: error });
      setisloading(false);
    }
  };
  console.log(promotionalBanner);
  const styles = {
    root: {
      padding: "0px",
    },
  };

  const ListSkeleton = ({ listsToRender }) => {
    return (
      <>
        {Array(listsToRender)
          .fill(1)
          .map((card, index) => (
            <div style={{ width: "40%", margin: "5px" }} key={index}>
              <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: "87%" }} />
              </Skeleton>
              <Box sx={{ display: "block", marginTop: "2px" }}>
                <Skeleton variant="rectangular" width="100%" height={22} />
              </Box>
              <Skeleton />
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <div className="page-layout">
        <div className="homepage_padding">
          <div className="p-5" style={{ borderRadius: "6px" }}>
            <div className="home-page-banner">
              <Carousel indicators={false}>
                {promotionalBanner?.map((item, i) => (
                  <a key={i} target="_blank" rel="noopener noreferrer">
                    <div className="home_dynamic_banner" style={{ cursor: "pointer", width: "100%", borderRadius: "7px", overflow: "hidden" }}>
                      <img
                        src={item.image}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "cover",
                          borderRadius: "7px",
                        }}
                      />
                    </div>
                  </a>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="">
            <section className="blog  mt-2 mb-2">
              <div className="blogcard">
                <div className="ourastologer_content text-center">
                  <h2 className="blog_sec pb-2 mt-5">Upcoming Pujas on Sri Mandir.</h2>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora ea saepe consequuntur eum quas iure quam. Maiores laudantium repudiandae
                    ex nisi ratione? Esse autem nihil provident illum sed. Qui deserunt maiores eaque ducimus eum! Voluptatem et, aspernatur mollitia tempore
                    debitis harum aut unde doloribus ad obcaecati error architecto veritatis esse ex quam omnis vero incidunt fugiat nesciunt ullam adipisci,
                    laudantium doloremque, ut necessitatibus. Eum consectetur aspernatur incidunt voluptates, eos nam repellendus aliquam minima qui doloremque
                    sapiente, quam debitis voluptate dolores?
                  </p>
                </div>

                {!isloading ? (
                  <div className="row">
                    {PujaArray?.length > 0 ? (
                      PujaArray?.map((data, index) => (
                        <div
                          className="col-md-6 col-lg-6 col-xl-4"
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(`/pujadetails/${data._id}`)}
                        >
                          <div className="blog_box_content m-2 " style={{ height: "auto" }}>
                            <div className="blog_section_image">
                              <img src={data?.pujaImage} alt="puja" />
                            </div>
                            <div className="p-2 " style={{ textAlign: "left" }}>
                              <h4>{data?.mandirName}</h4>
                              <h5>
                                {data?.title?.substring(0, 30) + ""} ({new Date(data?.pujaDatetime).toUTCString()})
                              </h5>

                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data?.aboutPuja?.substring(0, 100) + "...",
                                }}
                              ></span>
                            </div>
                            <div className="d-flex p-2" style={{ justifyContent: "space-between" }}>
                              <div className="" style={{ color: "#777" }}>
                                {data?.auther}
                              </div>
                              <div style={{ color: "#777" }}>{data?.Created_date?.substring(0, 10)}</div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <DataNotFound />
                    )}
                  </div>
                ) : (
                  <>
                    <div className="d-flex" style={{ width: "100%" }}>
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                    </div>
                    <div className="d-flex" style={{ width: "100%" }}>
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                    </div>
                    <div className="d-flex" style={{ width: "100%" }}>
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                      <ListSkeleton listsToRender={1} width="200" />
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default HOC(Puja);
