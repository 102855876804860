import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import s from "./partnerslider.module.css";

const PartnerSlider = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      partialVisibilityGutter: 400,
    },
    desktop: {
      breakpoint: { max: 2999, min: 1024 },
      items: 2,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1023, min: 650 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 651, min: 0 },
      items: 1,
      partialVisibilityGutter: 50,
    },
  };

  return (
    <>
      <div className={s["crousal-container"]}>
        <Carousel responsive={responsive} partialVisbile={true} autoPlay={false} rewind={true}>
          {[0, 0, 0].map((data, i) => (
            <div key={i} className={s["home-crousal"]}>
              <img style={{ cursor: "pointer", height: "100%", width: "100%" }} src={data?.banner_image} alt="banner" />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default PartnerSlider;
