import React, { useEffect, useState } from "react";
import HOC from "../../Common/HOC";
import "./puja.css";
import { notificationHandler } from "../utils/Notification";
import { useNavigate } from "react-router-dom";
import DataNotFound from "../DataNotFound";
import { get_all_puja_api, get_pooja_bookings_api } from "../api/puja";
import PujaDetails from "../DialogeBox/PujaDetails";
const MyBooking = () => {
  const [isloading, setisloading] = useState(false);
  const [BookingPujaArray, setBookingPujaArray] = useState([]);
  const [PujaDetailspopup, setPujaDetailspopup] = useState(false);
  const [poojid, setpoojid] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    get_all_get_pooja_bookings();
  }, []);

  const get_all_get_pooja_bookings = async () => {
    setisloading(true);
    try {
      const res = await get_pooja_bookings_api();
      if (res.data.status) {
        setBookingPujaArray(res.data.data);
        setisloading(false);
      } else {
        setisloading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
        console.log("data response error:::", res);
      }
    } catch (error) {
      console.log("data response error:::", error);
      notificationHandler({ type: "danger", msg: error });
      setisloading(false);
    }
  };

  return (
    <>
      <div className="page-layout">
        <div className="homepage_padding">
          <div className="">
            <section className="blog  mt-2 mb-2">
              <div className="blogcard">
                <div className="ourastologer_content text-center"></div>
                <div className="row">
                  {BookingPujaArray?.length > 0 ? (
                    BookingPujaArray?.map((data, index) => (
                      <div className="col-md-6 col-lg-6 col-xl-4" key={index} style={{ cursor: "pointer" }}>
                        <div className="blog_box_content m-2 " style={{ height: "auto" }}>
                          <div className="blog_section_image">
                            <img src={data?.puja_id?.pujaImage} alt="puja" />
                          </div>
                          <div className="p-2 " style={{ textAlign: "left" }}>
                            <h4>{data?.puja_id?.mandirName}</h4>
                            <h5>
                              {data?.puja_id?.title?.substring(0, 30) + ""} ({new Date(data?.puja_date)?.toUTCString()})
                            </h5>

                            <span
                              dangerouslySetInnerHTML={{
                                __html: data?.puja_id?.aboutPuja?.substring(0, 100) + "...",
                              }}
                            ></span>
                          </div>
                          <div className="d-flex p-2" style={{ justifyContent: "space-between" }}>
                            <div className="" style={{ color: "#777" }}>
                              {data?.puja_type}
                            </div>
                            <div style={{ color: "#777" }}>{data?.puja_id?.Created_date?.substring(0, 10)}</div>
                          </div>
                          <div className="" style={{ display: "flex", justifyContent: "space-between", padding: "0.5rem" }}>
                            <div className="view-btn">
                              <button
                                type="button"
                                className="btn_chat_profile online-status"
                                onClick={() => {
                                  setpoojid(data._id);
                                  setPujaDetailspopup(true);
                                }}
                                style={{ borderRadius: "5px" }}
                              >
                                Add Details
                              </button>
                            </div>
                            <div className="view-btn">
                              <button type="button" className="btn_chat_profile online-status" style={{ borderRadius: "5px" }}>
                                View
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <DataNotFound />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        <PujaDetails open={PujaDetailspopup} poojid={poojid} close={() => setPujaDetailspopup(!PujaDetailspopup)} />
      </div>
    </>
  );
};

export default HOC(MyBooking);
