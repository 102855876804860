import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../utils";

const puja_list = getBaseUrl() + "puja/pujalisting";
const pujabyinstaid = getBaseUrl() + "puja/pujabyinstaid";
const bookpuja = getBaseUrl() + "puja/bookpuja";
const get_pooja_bookings = getBaseUrl() + "user_api/get_pooja_bookings";
const add_user_details_in_puja = getBaseUrl() + "user_api/add_user_details_in_puja";

export const add_user_details_in_puja_api = async (data) => {
  try {
    return await axios.post(add_user_details_in_puja, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const get_pooja_bookings_api = async () => {
  try {
    return await axios.get(get_pooja_bookings, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const get_all_puja_api = async () => {
  try {
    return await axios.post(puja_list, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const bookpuja_api = async (data) => {
  try {
    return await axios.post(bookpuja, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const get_puja_by_id_api = async (id) => {
  const temp = {
    instaId: id,
  };
  try {
    return await axios.post(pujabyinstaid, temp, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default get_puja_by_id_api;
