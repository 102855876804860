import React, { useState } from "react";
import { blankValidator, emailValidator } from "../utils/Validation";
import { notificationHandler } from "../utils/Notification";
import { Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import { add_user_details_in_puja_api } from "../api/puja";

const PujaDetails = ({ open, close, onsubmit, poojid }) => {
  const [name, setname] = useState("");
  const [purposeOfPooja, setpurposeOfPooja] = useState("");
  const [gotra, setgotra] = useState("");
  const [email, setemail] = useState("");
  const [place, setplace] = useState("");
  const [whatsappNumber, setwhatsappNumber] = useState("");
  const [courierAddress, setcourierAddress] = useState("");
  const [isloading, setisloading] = useState("");

  const report_form_add = async () => {
    if (!blankValidator(name)) {
      notificationHandler({ type: "danger", msg: "Please Enter name" });
      return;
    }
    if (!blankValidator(purposeOfPooja)) {
      notificationHandler({ type: "danger", msg: "Please Enter Purpose Of Pooja" });
      return;
    }
    if (!blankValidator(gotra)) {
      notificationHandler({ type: "danger", msg: "Please Enter Gotra" });
      return;
    }
    if (!emailValidator(email)) {
      notificationHandler({ type: "danger", msg: "Please Enter Valid Email" });
      return;
    }
    if (!blankValidator(place)) {
      notificationHandler({ type: "danger", msg: "Please Enter place" });
      return;
    }

    if (!blankValidator(whatsappNumber)) {
      notificationHandler({ type: "danger", msg: "Please Enter whatsappNumber" });
      return;
    }
    if (!blankValidator(courierAddress)) {
      notificationHandler({ type: "danger", msg: "Please Enter courierAddress" });
      return;
    }
    addDetails();
  };

  const addDetails = async () => {
    try {
      let temp = {
        pujaBookingId: poojid,
        name: name,
        purposeOfPooja: purposeOfPooja,
        gotra: gotra,
        email: email,
        place: place,
        whatsappNumber: whatsappNumber,
        courierAddress: courierAddress,
      };

      const res = await add_user_details_in_puja_api(temp);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
        close();
        setname("");
        setplace("");
        setemail("");
        setgotra("");
        setpurposeOfPooja("");
        setwhatsappNumber("");
        setcourierAddress("");
        setisloading(false);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
        setname("");
        setplace("");
        setemail("");
        setgotra("");
        setpurposeOfPooja("");
        setwhatsappNumber("");
        setcourierAddress("");
        close();
        setisloading(false);
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: "Network Error!" });
      setisloading(false);
    }
  };

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={100}>
        <DialogTitle className="text-center">
          <span className="main_heading_mobile_number_registration">Report Details</span>
          <span className="float-right icon_color" onClick={() => close()} style={{ cursor: "pointer" }}>
            <i className="fa fa-times hover_cursor" aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent className="px-5">
          <div className="padding_desktop_view">
            <div className="pl-1">
              <div className="pt-3">
                <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setname(e.target.value)} />
              </div>
              <div className="pt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Purpose Of Pooja"
                  value={purposeOfPooja}
                  onChange={(e) => setpurposeOfPooja(e.target.value)}
                />
              </div>
              <div className="pt-3">
                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setemail(e.target.value)} />
              </div>
              <div className="pt-3">
                <input type="text" className="form-control" placeholder="Gotra" value={gotra} onChange={(e) => setgotra(e.target.value)} />
              </div>
              <div className="pt-3">
                <input type="text" className="form-control" placeholder="Place" value={place} onChange={(e) => setplace(e.target.value)} />
              </div>
              <div className="pt-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Whatsapp Number"
                  value={whatsappNumber}
                  onChange={(e) => setwhatsappNumber(e.target.value)}
                />
              </div>
              <div className="pt-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Courier Address"
                  value={courierAddress}
                  onChange={(e) => setcourierAddress(e.target.value)}
                />
              </div>

              <button type="submit" className="get_otp_btn mt-4" onClick={() => report_form_add()}>
                Submit
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="px-5 pb-2"></DialogActions>
      </Dialog>
    </>
  );
};

export default PujaDetails;
