import React from "react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";

const DownloadPopup = ({ open, close }) => {
  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" onClose={() => close()}>
        <DialogTitle className="text-center">
          <span className="main_heading_mobile_number_registration">Download App</span>
          <span className="float-right icon_color" onClick={() => close()}>
            <i className="fa fa-times hover_cursor" aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent className="px-5">
          <div className="padding_desktop_view">
            <div className="pl-1">
              <div>
                <div className=""></div>
                <h6>Download App Now For Best Offers</h6>
              </div>
              <button type="submit" className="get_otp_btn mt-4">
                Download
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="px-5 pb-2"></DialogActions>
      </Dialog>
    </div>
  );
};

export default DownloadPopup;
