import React from "react";
import OwlCarousel from "react-owl-carousel";
import "./Crousal.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import card2 from "../../images/Icons/b1.png";
import MatchMaking from "../../images/Icons/b2.png";
import Numerology from "../../images/Icons/b3.png";
import Kundli from "../../images/Icons/Frame3.png";
import Horoscope from "../../images/Icons/Frame5.png";
import AskQuestion from "../../images/Icons/Frame6.png";
import Detailed from "../../images/Icons/Frame2.png";
import getastro from "../../images/Icons/getastro.png";
import { FaArrowRight } from "react-icons/fa";
const Crousal = () => {
  const navigate = useNavigate();

  const options = {
    loop: true,
    nav: true,
    navText: ["<i className='fa fa-chevron-left arrow_right'></i>", "<i className='fa fa-chevron-right arrow_left '></i>"],
    autoplay: true,
    autoplayHoverPause: true,
    mouseDrag: true,
    margin: 50,
    center: false,
    dots: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  return (
    <>
      <div className="mt-3 mb-5">
        <div className="">
          <div className="ourastologer_content text-center">
            <h1 className="service_provide text-center">Services We Provide</h1>
          </div>
        </div>

        <OwlCarousel className="owl-theme " {...options}>
          <div class="servicecard card-1">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>
            <h2 class="card__title">Daily Horoscope</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-2">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Ask a Question</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-3">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Get Free Kundli</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-4">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Get detail Report</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-5">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Get Astrologers Services</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-1">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Know Your Daily Panchang</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-3">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Match Making</h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
          <div class="servicecard card-4">
            <div class="card__icon">
              <i class="fas fa-bolt"></i>
            </div>

            <h2 class="card__title">Know your Numerology </h2>
            <p class="card__apply">
              <a class="card__link" href="#">
                Now <FaArrowRight />
              </a>
            </p>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default Crousal;
