import React, { useState } from "react";
import s from "./faq.module.css";
import Expand from "react-expand-animated";
import chevron from "../../images/Icons/chevron-tb.svg";
import chevronn from "../../images/Icons/chevron-tb.svg";
const FAQ = ({ data, type, onsubmit }) => {
  const expandboxfun = (data, index) => {
    onsubmit(index);
  };
  return (
    <>
      <div className={s["termsof-service"]}>
        <div className="termsof-container">
          <div className={s["termsof-heading"]}>
            <h4 style={{ fontSize: "25px" }}>{type == "seo" ? "Frequently Asked Questions" : "Benifits"}</h4>
          </div>
          <div className={s["terms-content"]}>
            {data.map((data, index) => (
              <div key={index}>
                <div className={s["terms-data"]}>
                  <div className={s["faq-title"]} onClick={() => expandboxfun(data, index)}>
                    <div className={s["faq-heading"]} dangerouslySetInnerHTML={{ __html: data.heading }}></div>
                    <div>{data.show == true ? <img src={chevron} /> : <img src={chevronn} />}</div>
                  </div>

                  <Expand open={data.show}>
                    <div dangerouslySetInnerHTML={{ __html: data.desc }}></div>
                  </Expand>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
