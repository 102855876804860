import React, { useState, useEffect } from "react";
import HOC from "../../Common/HOC";
import { notificationHandler } from "../utils/Notification";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "./puja.css";
import OurAstrologerCrousal from "../Crousal/OurAstrologerCrousal";
import homeapi from "../api/homeapi";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share";
import Cookies from "js-cookie";
import Loder from "../Loder/Loder";
import BreadcrumbSection from "../BreadcrumbSection/BreadcrumbSection";
import { Helmet } from "react-helmet";
import get_puja_by_id_api from "../api/puja";
import Carousel from "react-material-ui-carousel";
import FAQ from "../AstrogurujiiFAQ/FAQ";
import PackageDetails from "../DialogeBox/PackageDetails";

const Pujadetails = () => {
  const [isloading, setisloading] = useState(false);
  const [blogdetail, setblogdetail] = useState({});
  const [PackageDetailspopup, setPackageDetailspopup] = useState(false);
  const [packagedata, setpackagedata] = useState("");
  const location = useLocation();
  const [seotitle, setseotitle] = useState("Astrogurujii Guru");
  const [seoimage, setseoimage] = useState("https://astrogurujii.com/static/media/astroGurulogo.f965aa0eb4f9ff946091.png");
  const token = Cookies.get("token");
  const [AstrologerList, setAstrologerList] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    blogdetailspage();
    LiveAstroData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    blogdetailspage();
  }, [id]);

  const shareUrl = `https://astrogurujii.com/pujadetails/${id}`;

  ///api integration get Blog Detail
  const blogdetailspage = async () => {
    try {
      const res = await get_puja_by_id_api(id);
      setisloading(true);

      if (res.data.status) {
        setblogdetail(res?.data?.data);
        console.log(res);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setisloading(false);
      }
    } catch (error) {
      console.log("data response error:::", error);
      notificationHandler({ type: "danger", msg: error });
      setisloading(false);
    }
  };

  /// top astrologer list
  const LiveAstroData = async () => {
    try {
      setisloading(true);
      const res = await homeapi();
      if (res.data.status) {
        setAstrologerList(res?.data?.astrologer);
        setisloading(false);
      } else {
        console.log("data response error:::", res);
        notificationHandler({ type: "danger", msg: res.data.message });
        setisloading(false);
      }
    } catch (error) {
      console.log("data response error:::", error);
      notificationHandler({ type: "danger", msg: error });
      setisloading(false);
    }
  };

  const [filterfaqArry, setfilterfaqArry] = useState([]);

  // Function to filter and format FAQ data
  const filterfaq = () => {
    return (
      blogdetail?.faq?.map((data) => {
        const show = false; // Initialize `show` to false
        const heading = data.question; // Extract the question
        const desc = data.answer; // Extract the answer
        return { show, heading, desc }; // Return an object containing show, heading, and desc
      }) || []
    );
  };

  // useEffect to update the filterfaqArry when blogdetail changes
  useEffect(() => {
    if (blogdetail?.faq) {
      const formattedFaq = filterfaq();
      setfilterfaqArry(formattedFaq); // Update state with filtered FAQ data
    }
  }, [blogdetail]); // Dependency array ensures the effect runs when blogdetail changes

  // Function to toggle the `show` property of a specific FAQ item
  const showfaqfun = (index) => {
    const updatedFaq = [...filterfaqArry];
    updatedFaq[index].show = !updatedFaq[index].show; // Toggle show property
    setfilterfaqArry(updatedFaq); // Update state with modified array
  };

  const [filterbenifitsArry, setfilterbenifitsArry] = useState([]);

  // Function to filter and format FAQ data
  const filterbenifits = () => {
    return (
      blogdetail?.benifits?.map((data) => {
        const show = false; // Initialize `show` to false
        const heading = data.title; // Extract the question
        const desc = data.description; // Extract the answer
        return { show, heading, desc }; // Return an object containing show, heading, and desc
      }) || []
    );
  };

  // useEffect to update the filterfaqArry when blogdetail changes
  useEffect(() => {
    if (blogdetail?.benifits) {
      const formattedbenifits = filterbenifits();
      setfilterbenifitsArry(formattedbenifits); // Update state with filtered FAQ data
    }
  }, [blogdetail]); // Dependency array ensures the effect runs when blogdetail changes

  // Function to toggle the `show` property of a specific FAQ item
  const showbenifitsfun = (index) => {
    const updatedbenifits = [...filterbenifitsArry];
    updatedbenifits[index].show = !updatedbenifits[index].show; // Toggle show property
    setfilterbenifitsArry(updatedbenifits); // Update state with modified array
  };
  return (
    <>
      <Helmet>
        <title>Astrology Predictions by Astrogurujii Online Astrologers - Astrogurujii</title>
      </Helmet>
      <div className="">
        <div className="container mt-4 mb-2">
          <BreadcrumbSection tittle="Astro Gurujii Puja" />
          <div className="div_shadow mt-4">
            <div className="row">
              {/* <div className="col-md-12 col-lg-8">
                <div className="data_blog d-flex">
                  <h1 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000" }}>
                    About Details
                  </h1>
                </div>
              </div> */}
            </div>
            <div className="pujadetails">
              <div className="about-section">
                <div className="layout-section">
                  <h2 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000", paddingBlock: "0.5rem" }}>
                    {blogdetail?.mandirName}
                  </h2>
                  <p>
                    {blogdetail?.purposeOfPooja} - <b>{new Date(blogdetail?.pujaDate).toUTCString()}</b>
                  </p>
                </div>
              </div>
              <div className="">
                <Carousel indicators={true}>
                  {blogdetail?.bannerImages?.map((item, i) => {
                    return (
                      <a key={i} target="_blank" rel="noopener noreferrer">
                        <div className="home_dynamic_banner" style={{ cursor: "pointer", width: "100%", borderRadius: "7px", overflow: "hidden" }}>
                          <img
                            src={item}
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "300px",
                              objectFit: "cover",
                              borderRadius: "7px",
                            }}
                          />
                        </div>
                      </a>
                    );
                  })}
                </Carousel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="data_blog d-flex">
                  <h1 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000", paddingTop: "1rem" }}>
                    About Puja
                  </h1>
                </div>
                <div className="">
                  <img className="blog_detail_img" src={blogdetail?.pujaImage} />

                  <p
                    className="resonsive_image mt-3"
                    dangerouslySetInnerHTML={{
                      __html: blogdetail?.aboutPuja,
                    }}
                  ></p>
                  {/* <div className="share-icons-react mb-3">
                    <div>
                      <FacebookShareButton round url={shareUrl} quote="Demo" className="Demo__some-network__share-button">
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                    </div>
                    <div>
                      <WhatsappShareButton url={shareUrl} quote="Demo" className="Demo__some-network__share-button">
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>
                    </div>
                    <div>
                      <TwitterShareButton url={shareUrl} quote="Demo" className="Demo__some-network__share-button">
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="data_blog d-flex">
                  <h1 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000" }}>
                    About Temple
                  </h1>
                </div>
                <div className="">
                  <img className="blog_detail_img" src={blogdetail?.templeImage} />

                  <p
                    className="resonsive_image mt-3"
                    dangerouslySetInnerHTML={{
                      __html: blogdetail?.aboutTempalDescription,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="faq-section py-3">
              {filterbenifitsArry && <FAQ data={filterbenifitsArry} type="puja" onsubmit={(index) => showbenifitsfun(index)} />}
            </div>
            <div className="puja-packages">
              <h1 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000" }}>
                Puja Packages
              </h1>
              <div className="container py-4">
                <div className="row">
                  {blogdetail?.packages?.map((data, index) => (
                    <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                      <ul className="price list-unstyled text-center border rounded p-3 shadow">
                        <li className="header bg-primary text-white py-2">{data?.packageType}</li>
                        <li className="grey py-2">Price - {data?.packagePrice}</li>
                        <li className="py-2">{data?.packageName}</li>
                        {data?.packageDescription.map((item, i) => (
                          <li key={i} className="py-1">
                            {item}
                          </li>
                        ))}
                        <li className="grey py-2">
                          <a
                            className="btn btn-primary py-2 px-4"
                            onClick={() => {
                              setpackagedata(data);
                              setPackageDetailspopup(true);
                            }}
                          >
                            View
                          </a>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="faq-section py-3">{filterfaqArry && <FAQ data={filterfaqArry} type="seo" onsubmit={(index) => showfaqfun(index)} />}</div>
            </div>
          </div>
        </div>
        <section className="container ourastrologer mt-1 mb-4">
          <OurAstrologerCrousal astro={AstrologerList} />
        </section>
      </div>
      <PackageDetails open={PackageDetailspopup} data={blogdetail} close={() => setPackageDetailspopup(!PackageDetailspopup)} packagedata={packagedata} />
      <Loder loading={isloading} />
    </>
  );
};

export default HOC(Pujadetails);
