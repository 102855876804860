import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from "@material-ui/core";
import Cookies from "js-cookie";
import Carousel from "react-material-ui-carousel";
import { bookpuja_api } from "../api/puja";
import { notificationHandler } from "../utils/Notification";

const PackageDetails = ({ open, close, data, packagedata }) => {
  const auth = Cookies.get("auth");
  const userID = Cookies.get("userID");

  const bookpuja_api_function = async () => {
    if (auth !== "true") {
      alert("PLease login first");
      return;
    }
    const temp = {
      puja_id: data._id.toString(),
      puja_date: data.pujaDate.toString(),
      puja_amount: packagedata.packagePrice.toString(),
      puja_type: packagedata.packageType.toString(),
      user_id: userID.toString(),
    };
    try {
      const res = await bookpuja_api(temp);
      if (res.data.status) {
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        console.log("data response error:::", res);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log("data response error:::", error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={100} onClose={close}>
        <DialogTitle className="text-center">
          <span className="main_heading_mobile_number_registration">Package Details</span>
          <span className="float-right icon_color" onClick={close} style={{ cursor: "pointer" }}>
            <i className="fa fa-times hover_cursor" aria-hidden="true"></i>{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="padding_desktop_view">
            <div className="">
              <div className="">
                <Carousel indicators={true}>
                  {data?.bannerImages?.map((item, i) => {
                    return (
                      <a key={i} target="_blank" rel="noopener noreferrer">
                        <div className="home_dynamic_banner" style={{ cursor: "pointer", width: "100%", borderRadius: "7px", overflow: "hidden" }}>
                          <img
                            src={item}
                            loading="lazy"
                            style={{
                              width: "100%",
                              height: "150px",
                              objectFit: "cover",
                              borderRadius: "7px",
                            }}
                          />
                        </div>
                      </a>
                    );
                  })}
                </Carousel>
              </div>
              <div className="about-section">
                <div className="layout-section">
                  <h3 className="blog_title_main" style={{ textTransform: "capitalize", color: "#000", paddingBlock: "0.5rem" }}>
                    {data?.mandirName}
                  </h3>
                  <p>
                    {data?.purposeOfPooja} - <b>{new Date(data?.pujaDate).toUTCString()}</b>
                  </p>
                </div>
              </div>
              <div className="table-container">
                <table>
                  <tr>
                    <th>Payment Summary</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>Package</td>
                    <td>{packagedata.packageType}</td>
                  </tr>

                  <tr>
                    <td>Puja Plan</td>
                    <td>{packagedata.packageName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      <b>{packagedata.packagePrice}</b>
                    </td>
                  </tr>
                </table>
              </div>
              <button type="submit" className="get_otp_btn mt-4" onClick={() => bookpuja_api_function()}>
                Make Payment
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="px-5 pb-2"></DialogActions>
      </Dialog>
    </div>
  );
};

export default PackageDetails;
